<template>
  <Page
    has-actions
    :subtitle="subtitle"
    :loading="brand_loading"
  >
    <b-switch
      v-if="brand"
      slot="actions"
      :disabled="action == 'create'"
      :value="brand.active"
      type="is-success"
      @input="activateOrDesactivateBrand"
    >
      {{ $tc("active", 1) }}
    </b-switch>
    <Card content-class="p-0">
      <div
        v-if="brand_loading"
        style="min-height: 200px"
      >
        <b-loading
          v-model="brand_loading"
          :is-full-page="false"
        />
      </div>
      <BrandForm
        v-else
        :key="brand ? brand.id : 0"
        :brand="brand"
        :index-value="index"
        :sites="sites"
        :action="action"
        @updateUsers="updateUsers"
      />
    </Card>
  </Page>
</template>

<script>
import BrandForm from "@/components/forms/Brand";
export default {
  components: {
    BrandForm
  },
  data() {
    return {
      subtitle: "",
      action: "",
      brand: {},
      sites: [],
      brand_loading: true,
      index: -1
    };
  },
  created() {
    if (this.$route.params.brand_id) {
      if (this.$can("update", "catalog")) {
        this.action = "update";
        this.updateTitle(this.$t("edit_noun"));
      } else if (this.$can("read", "catalog")) {
        this.action = "read";
        this.updateTitle(this.$tc("brand", 1));
      }
      this.getBrand(this.$route.params.brand_id);
    } else {
      this.action = "create";
      this.updateTitle(this.$t("new_brand"));
      this.getBrand(0);
    }
  },
  methods: {
    activateOrDesactivateBrand(){
      if (this.brand.active) {
        this.$axios
        .delete("brands/" + this.brand.id)
          .then(() => {
            this.brand.active = false;
            this.$root.notify(this.$t("successfully_deleted"), "is-success");
          })
          .catch(e => this.clientError(e));
      } else {
        this.$axios
        .patch("brands/" + this.brand.id + "/restore")
          .then(() => {
            this.brand.active = true;
            this.$root.notify(this.$t("successfully_restored"), "is-success");
          })
          .catch(e => this.clientError(e));
      }
    },
    updateUsers(value, index) {
      this.brand.users[index].discount = value;
    },
    getBrand(brand_id) {
      this.brand_loading = true;
      this.$axios
        .get("brands/" + brand_id)
        .then(res => {
          if (this.action == "create") {
            this.setSites(res.data.filters.sites);
            this.index = -1;
          } else {
            this.brand = JSON.parse(JSON.stringify(res.data.brand));
            this.sites = JSON.parse(JSON.stringify(res.data.brand.trans));
            this.subtitle = res.data.brand.brand;
            this.index = 1;
          }
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.brand_loading = false));
    },
    setSites(sites) {
      for (let i = 0; i < sites.length; i++) {
        let site = sites[i];
        let languages = [];
        for (let j = 0; j < site.languages.length; j++) {
          languages.push({
            ...site.languages[j],
            site_id: site.id,
            h1: null,
            meta_title: null,
            meta_description: null,
            description: null
          });
        }
        this.sites.push({
          id: site.id,
          label: site.label,
          type: site.type,
          languages: languages
        });
      }
    }
  }
};
</script>

<template>
  <div class="wrap">
    <b-tabs
      v-model="step"
      position="is-centered"
      class="block"
      expanded
    >
      <b-tab-item
        :label="$t('general')"
        value="1"
      >
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
          class="mt-2"
          tag="div"
        >
          <form
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(saveGeneral)"
          >
            <div class="columns is-multiline is-mobile">
              <div class="column is-12">
                <ImageUpload
                  :images="images"
                  :disabled="disableInfos"
                  :max-files="1"
                  @updateFiles="onUpdateFiles"
                />
              </div>

              <div class="column is-6-tablet is-12-mobile">
                <b-input-validation
                  v-model="myBrand.brand"
                  :disabled="disableInfos"
                  rules="required"
                  :label="$tc('brand', 1)"
                  vid="brand"
                  :placeholder="placeholders.brand.name"
                  maxlength="30"
                  counter="30"
                  autofocus
                  @blur="validBrandName($event)"
                />
              </div>

              <div class="column is-6-tablet is-12-mobile">
                <b-input-validation
                  v-model="myBrand.permalink"
                  :disabled="disableInfos || !!(myBrand && myBrand.id)"
                  :rules="permalink_validation"
                  validation-mode="lazy"
                  :label="$tc('permalink', 1)"
                  vid="permalink"
                  :placeholder="placeholders.brand.permalink"
                  maxlength="30"
                  counter="30"
                />
              </div>

              <div class="column is-4-tablet is-6-mobile">
                <b-input-validation
                  v-model="myBrand.discount_b2b"
                  suffix="%"
                  :disabled="disableInfos"
                  :label="$t('discount_b2b') + ' (' + $t('optional') + ')'"
                  input-type="number"
                  step=".01"
                  :placeholder="placeholders.number.decimal_2"
                />
              </div>

              <div class="column is-4-tablet is-6-mobile">
                <b-input-validation
                  v-model="myBrand.rank"
                  name="rank"
                  :disabled="disableInfos"
                  :label="$t('rank') + ' (' + $t('optional') + ')'"
                  input-type="number"
                  :placeholder="placeholders.price.int"
                />
              </div>

              <div class="column is-4-tablet is-12-mobile">
                <b-switch
                  v-model="myBrand.noindex"
                  class="mt-2"
                  :disabled="disableInfos"
                >
                  {{ $t("noindex") }}
                </b-switch>
              </div>

              <div class="column">
                <b-button
                  type="is-primary"
                  :disabled="save_general_loading || disableInfos"
                  :loading="save_general_loading"
                  tag="input"
                  native-type="submit"
                  :value="$t('save')"
                >
                  {{ $t("save") }}
                </b-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </b-tab-item>
      <b-tab-item
        v-if="$can('read', 'catalog.brands.seo')"
        :disabled="
          !(
            action === 'read' ||
            action === 'update' ||
            myBrand.id !== undefined
          )
        "
        :label="$t('seo')"
        value="2"
      >
        <ValidationObserver
          v-if="step == '2'"
          ref="observer"
          v-slot="{ handleSubmit }"
          class="mt-2"
          tag="div"
        >
          <form
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(saveTrans)"
          >
            <b-tabs
              type="is-boxed"
              class="mb-0 paddingless"
            >
              <b-tab-item
                v-for="(site, i) in sites"
                :key="'tab' + site.id"
                :value="`${site.id}`"
              >
                <template slot="header">
                  <img
                    :src="'/img/sites/' + site.id + '.svg'"
                    width="20"
                    height="20"
                    alt
                    class="mr-2"
                  >
                  {{ site.label }}
                </template>
                <b-tabs
                  class="pt-0"
                  :class="$device.mobile ? 'paddingless' : 'paddingless-right'"
                  :vertical="!$device.mobile"
                >
                  <b-tab-item
                    v-for="(lang, j) in site.languages"
                    :key="'lang-' + lang.iso"
                    header-class="mb-4"
                  >
                    <template slot="header">
                      <img
                        :src="'/img/langs/' + lang.iso + '.svg'"
                        width="18"
                        height="18"
                        class="mr-2"
                      >
                      {{ lang.label }}
                    </template>
                    <div>
                      <div class="columns is-multiline is-mobile ml-2">
                        <div class="column is-6-tablet is-12-mobile">
                          <b-input-validation
                            v-model="sites[i]['languages'][j]['h1']"
                            :placeholder="placeholders.brand.h1"
                            :vid="'h1' + site.id + lang.iso"
                            :disabled="disableLangs"
                            :label="'h1' + ' (' + $t('optional') + ')'"
                            maxlength="80"
                            counter="80"
                          />
                        </div>

                        <div class="column is-6-tablet is-12-mobile">
                          <b-input-validation
                            v-model="sites[i]['languages'][j]['meta_title']"
                            :placeholder="placeholders.brand.meta_title"
                            :vid="'meta_title' + site.id + lang.iso"
                            :disabled="disableLangs"
                            :label="
                              $t('meta_title') + ' (' + $t('optional') + ')'
                            "
                            maxlength="90"
                            counter="90"
                          />
                        </div>

                        <div class="column is-12">
                          <b-input-validation
                            v-model="
                              sites[i]['languages'][j]['meta_description']
                            "
                            :placeholder="placeholders.brand.meta_description"
                            input-type="textarea"
                            :vid="'meta_description' + site.id + lang.iso"
                            :label="
                              $t('meta_description') +
                                ' (' +
                                $t('optional') +
                                ')'
                            "
                            rows="2"
                            auto-grow
                            :disabled="disableLangs"
                            maxlength="300"
                            counter="300"
                            :suffix="true"
                          >
                            <b-dropdown
                              slot="suffix"
                              position="is-bottom-left"
                              aria-role="list"
                            >
                              <template #trigger="{ active }">
                                <b-button
                                  :label="$tc('variable', 2)"
                                  :icon-right="
                                    active ? 'chevron-up' : 'chevron-down'
                                  "
                                />
                              </template>

                              <b-dropdown-item
                                v-for="v in copyVariables.list"
                                :key="v.label"
                                aria-role="listitem"
                                @click="$root.copytoClipboard(v.value)"
                              >
                                {{ v.label }}
                              </b-dropdown-item>
                            </b-dropdown>
                          </b-input-validation>
                        </div>

                        <div class="column is-12">
                          <b-input-validation
                            v-model="
                              sites[i]['languages'][j]['description_short']
                            "
                            :placeholder="placeholders.brand.description_short"
                            input-type="textarea"
                            :vid="'description_short' + site.id + lang.iso"
                            :label="
                              $t('description_short') +
                                ' (' +
                                $t('optional') +
                                ')'
                            "
                            rows="2"
                            auto-grow
                            :disabled="disableLangs"
                            maxlength="512"
                            counter="512"
                          />
                        </div>

                        <div class="column is-12 pb-0">
                          <b-input-validation
                            v-model="sites[i]['languages'][j]['description']"
                            :placeholder="placeholders.brand.description"
                            input-type="textarea"
                            :vid="'description' + site.id + lang.iso"
                            :disabled="disableLangs"
                            :label="
                              $t('description') + ' (' + $t('optional') + ')'
                            "
                            rows="3"
                            auto-grow
                          />
                        </div>
                      </div>
                    </div>
                  </b-tab-item>
                </b-tabs>
              </b-tab-item>
            </b-tabs>
            <b-button
              type="is-primary"
              :disabled="save_langs_loading || disableLangs"
              :loading="save_langs_loading"
              tag="input"
              native-type="submit"
              :value="$t('save')"
            >
              {{ $t("save") }}
            </b-button>
          </form>
        </ValidationObserver>
      </b-tab-item>

      <b-tab-item
        v-if="
          brand.users && brand.users.length && $can('read', 'users.business')
        "
        :label="$t('discount')"
        value="3"
      >
        <b-table
          v-if="step == '3'"
          :data="brand.users"
          paginated
          :per-page="pagination.perPage"
          pagination-position="top"
          pagination-size="is-small"
          :current-page="pagination.page"
          :total="total"
        >
          <template v-for="head in discountHeaders">
            <b-table-column
              v-if="head.value == 'discount'"
              :key="head.value"
              v-bind="head"
              :width="head.width"
              :label="head.text"
              :field="head.value"
              cell-class="editable"
              sortable
              searchable
            >
              <template
                v-if="head.searchable && head.value == 'discount'"              
                #searchable="props"
              >
                <b-input
                  v-model="props.filters[props.column.field]"
                  :placeholder="placeholders.number['2']"
                  rounded
                  size="is-small"
                />
              </template> 
              <template
                v-slot="props"
              >
                <span
                  class="edit"
                  @click="openInput(props.row.id, props.row.discount)"
                >
                  <input
                    :placeholder="$t('discount')"
                    inputType="number"
                    step=".01"
                    autofocus
                    :name="'discount' + props.row.id"
                    :value="props.row.discount"
                    :class="
                      editDiscount === null || editDiscount !== props.row.id
                        ? ''
                        : 'show'
                    "
                    @blur="editDiscount = null"
                    @keyup.enter="updateDiscount($event, props.index, props.row)"
                  >
                  {{ props.row.discount ? `${props.row.discount}%` : "-" }}
                </span>
              </template>
            </b-table-column>
            <b-table-column
              v-else
              :key="head.value"
              v-bind="head"
              :width="head.width"
              :label="head.text"
              :field="head.value"
              sortable
              :searchable="head.searchable"
            >
              <template
                v-if="head.searchable && head.value == 'id'"              
                #searchable="props"
              >
                <b-input
                  v-model="props.filters[props.column.field]"
                  :placeholder="placeholders.number['2']"
                  rounded
                  size="is-small"
                />
              </template> 
              <template
                v-else-if="head.searchable && head.value == 'company'"              
                #searchable="props"
              >
                <b-input
                  v-model="props.filters[props.column.field]"
                  :placeholder="placeholders.company.name"
                  rounded
                  size="is-small"
                />
              </template> 
              
              <template
                v-if="head.value == 'id'"
                v-slot="props"
              >
                <router-link
                  v-if="$can('read', 'users')"
                  :to="'/users/' + props.row.id"
                >
                  {{ props.row.id }}
                </router-link>
                <span v-else>{{ props.row.id }}</span>
              </template>
              <template
                v-else-if="head.value == 'company'"
                v-slot="props"
              >
                {{ props.row.company || "-" }}
              </template>
            </b-table-column>
          </template>
        </b-table>
        <Pagination
          :per-page="pagination.perPage"
          :per-pages="rowsPerPage"
          :total="total"
          :current-page="pagination.page"
          @update-per-page="val => (pagination.perPage = val)"
          @update-current-page="val => (pagination.page = val)"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ImageUpload from "@/components/forms/elements/ImageUpload";

export default {
  components: {
    ImageUpload
  },
  props: {
    brand: {
      type: Object,
      required: true
    },
    indexValue: {
      type: Number,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      step: "1",
      myBrand: null,

      // general
      images: [],
      image: null,
      save_general_loading: false,
      disableInfos: false,

      // Seo
      save_langs_loading: false,
      disableLangs: false,

      // discount
      discountHeaders: [
        { text: this.$i18n.tc("id", 1), value: "id", width: "200", searchable: true,},
        { text: this.$i18n.tc("company", 1), value: "company", width: "300", searchable: true,},
        { text: this.$i18n.t("discount"), value: "discount", width: "100", searchable: true,}
      ],
      editDiscount: null,
      discountValue: null,
      rowsPerPage: [50, 100, 200],
      pagination: { perPage: 50, page: 1 },
      total: 0,
    };
  },
  computed: {
    method() {
      if (this.myBrand && this.myBrand.id) return "put";
      else return "post";
    },
    permalink_validation: function() {
      let valid_permalink = `required`;
      if (
        this.myBrand.permalink &&
        this.myBrand.id &&
        this.action != "update"
      ) {
        return `${valid_permalink}|valid_brand_permalink:${this.myBrand.permalink}/check/${this.myBrand.id}`;
      } else if (this.myBrand.permalink && this.myBrand.id === undefined)
        return `${valid_permalink}|valid_brand_permalink:${this.myBrand.permalink}/check`;
      else {
        return `required`;
      }
    }
  },
  created() {
    if (this.brand){ 
      this.myBrand = { ...this.brand };
      this.total = this.brand.users.length;
    }
    if (this.brand.image) this.images = [{ ...this.brand.image }];
    if (this.action === "read") {
      this.disableInfos = true;
      this.disableLangs = true;
    }
  },
  methods: {
    changeStep(step) {
      if (this.action != "update") {
        this.step = `${step}`;
      }
    },
    onUpdateFiles(files) {
      this.image = files[0];
    },
    validBrandName(event) {
      if (event.target.value.length > 0 && !(this.myBrand && this.myBrand.id)) {
        let url =
          "brands/permalink/" +
          encodeURIComponent(event.target.value.split("/").join(" "));
        let check = this.action === "update" ? "/" + this.myBrand.id : "";
        this.$axios
          .get(url + check)
          .then(res => {
            this.$set(this.myBrand, "permalink", res.data.permalink);
          })
          .catch(e => {
            if (this.action == "create") {
              let permalink =
                e.response && e.response.data && e.response.data.permalink;

              this.$set(this.myBrand, "permalink", permalink);
            }
          });
      }
    },
    saveGeneral() {
      this.save_general_loading = true;
      let brand_id = "";

      const formData = new FormData();
      formData.append("image", this.image);
      formData.set("brand", this.myBrand.brand);
      formData.set("permalink", this.myBrand.permalink);
      formData.set("discount_b2b", this.myBrand.discount_b2b);
      formData.set("rank", this.myBrand.rank);
      formData.set("noindex", this.myBrand.noindex ? 1 : 0);

      if (this.myBrand && this.myBrand.id) {
        brand_id = "/" + this.myBrand.id;
        formData.set("_method", "put");
      }

      this.$axios({
        method: "post",
        url: "brands" + brand_id,
        data: formData,
        headers: { "content-type": "multipart/form-data" }
      })
        .then(res => {
          if (!this.myBrand.id) {
            this.$root.notify(this.$t("successfully_saved"), "is-success");
          } else {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          }
          this.myBrand = res.data.brand;
          this.changeStep(2);
        })
        .catch(e => {
          this.clientError(e);
        })
        .finally(() => (this.save_general_loading = false));
    },
    saveTrans() {
      if (this.$can("read", "catalog.seo")) {
        this.save_langs_loading = true;
        let brand_id = this.myBrand.id ? "/" + this.myBrand.id : "";

        let seo = [];
        let i;
        for (i = 0; i < this.sites.length; i++) {
          let j;
          for (j = 0; j < this.sites[i].languages.length; j++) {
            let languages = { ...this.sites[i]["languages"][j] };
            languages.lang_iso = this.sites[i]["languages"][j].iso;
            delete languages.iso;
            delete languages.label;
            seo.push(languages);
          }
        }

        this.$axios({
          method: this.method,
          url: "brands" + brand_id + "/seo",
          data: seo
        })
          .then(() => {
            if (this.action == "create") {
              this.$root.notify(this.$t("successfully_saved"), "is-success");
            } else {
              this.$root.notify(this.$t("successfully_updated"), "is-success");
            }
          })
          .catch(e => {
            this.clientError(e);
          })
          .finally(() => (this.save_langs_loading = false));
      }
    },
    openInput(item_id, value) {
      if (this.$can("update", "users.business")) {
        this.discountValue = value;
        this.editDiscount = item_id;
      }
    },
    updateDiscount(e, index, item) {
      let data = {};
      data.discount = e.target && e.target.value && parseFloat(e.target.value);
      this.$axios
        .put(`users/${item.id}/brands_discounts/${this.brand.id}`, data)
        .then(() => {
          this.$emit("updateUsers", data.discount, index);
          this.editDiscount = null;
        })
        .catch(e => this.$root.clientError(e));
    }
  }
};
</script>

